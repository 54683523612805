import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'List of Uploadcare sub-processors',
  description: '',
  header: 'Uploadcare sub-processors'
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "1-sub-processors-that-process-data-in-your-storage"
    }}><a parentName="h3" {...{
        "href": "#1-sub-processors-that-process-data-in-your-storage"
      }}>{`1. Sub-processors that process data in your storage`}</a></h3>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Sub-processor`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Activity`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Country`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://akamai.com"
              }}>{`Akamai`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Content delivery network`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://aws.amazon.com"
              }}>{`Amazon Web Services`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Cloud service provider`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://www.remove.bg/"
              }}>{`Kaleido`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Visual AI solution tool`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Austria`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://www.zamzar.com"
              }}>{`Zamzar`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Online document conversion tool`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`UK`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://www.brightcove.com/en/products/zencoder/"
              }}>{`Zencoder`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Video processing tool`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr></tbody></table>
    <h3 {...{
      "id": "2-sub-processors-for-marketing-and-analytical-purposes"
    }}><a parentName="h3" {...{
        "href": "#2-sub-processors-for-marketing-and-analytical-purposes"
      }}>{`2. Sub-processors for marketing and analytical purposes`}</a></h3>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Sub-processor`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Activity`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Country`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://facebook.com/business"
              }}>{`Facebook for Business`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Advertising platform`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://cloud.google.com"
              }}>{`Google Cloud`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Cloud storage provider`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://marketingplatform.google.com"
              }}>{`Google Marketing Platform`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Unified advertising and analytics platform`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://ads.microsoft.com"
              }}>{`Microsoft Advertising`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Advertising platform`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://segment.com"
              }}>{`Segment`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Customer data aggregation platform`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://www.tableau.com"
              }}>{`Tableau`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Analytics platform`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://www.talend.com/"
              }}>{`Talend`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Analytics platform`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr></tbody></table>
    <h3 {...{
      "id": "3-sub-processors-that-enable-us-to-run-business-and-may-have-some-information-about-you-excluding-data-in-your-storage"
    }}><a parentName="h3" {...{
        "href": "#3-sub-processors-that-enable-us-to-run-business-and-may-have-some-information-about-you-excluding-data-in-your-storage"
      }}>{`3. Sub-processors that enable us to run business and may have some information about you (excluding data in your storage)`}</a></h3>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Sub-processor`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Activity`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Country`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://calendly.com"
              }}>{`Calendly`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Meeting scheduling tool`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://customer.io"
              }}>{`Customer.io`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Automated email platform`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://workspace.google.com"
              }}>{`Google Workspace`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Email and documents for business`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://helpscout.com"
              }}>{`Help Scout`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Customer support services`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://hubspot.com"
              }}>{`HubSpot`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`CRM platform`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://kickbox.com"
              }}>{`Kickbox`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Email verification`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://www.mezmo.com"
              }}>{`Mezmo`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Platform to control log data`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://rollbar.com"
              }}>{`Rollbar`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Continuous code improvement platform`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://slack.com"
              }}>{`Slack`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Internal communication platform`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://stripe.com"
              }}>{`Stripe`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Payment infrastructure`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><a parentName="p" {...{
                "href": "https://zapier.com"
              }}>{`Zapier`}</a></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Workflow automation tool`}</p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`United States`}</p></td></tr></tbody></table>
    <p>{`If you want to subscribe to sub-processor updates, please send us an email to
`}<a parentName="p" {...{
        "href": "mailto:trust@uploadcare.com"
      }}>{`trust@uploadcare.com`}</a>{` and include "I want to subscribe to
Uploadcare's sub-processer updates mailing list" in the text.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      